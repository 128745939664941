declare const window: any;
declare function loadChat(a: any, b: any, c: any): any;
declare function getValidAccessToken(): any;
declare function getStaffAccessTokenForStaffbot(): any;
declare function loadAvaamoChatbotForCuStudent(): any;
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { IDToken, OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';

import { AssetsPath } from 'src/static-assets/assets-path';
import { environment } from 'src/environments/environment';
import { StudentAvaamoBotService } from '../../../services/student-avaamo-bot.service';
import { ScriptLoaderService } from '../../../services/load-script-service.service';
@Component({
  selector: 'app-avaamo',
  templateUrl: './avaamo.component.html',
  styleUrls: ['./avaamo.component.scss']
})
export class AvaamoComponent {

    public toShowAvaamo = false;
    public toShowGCP = false;
    public showAcknowledge = false;
    public disabledButton = false;
    public toshowTick = false;
    public chatIconShow = false;
    public isDisabled = true;
    public boxArea = "box1";
    public buttonArea = "btn-area";
    public assetsPath = AssetsPath;
    public title = `Chat bots for ${environment.name} University`;
    public userData = {
      name: 'no user',
      email: 'no user'
    };
    acknowledged: boolean = false;
    studentChatbotName: string = environment.studentChatbotName;
    chatbotName: string = environment.chatbotName;
    showFullscreen: boolean = false;
    chatbotId: string = "";
    acknowledgmentVerified: string = 'false';

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
      location.reload()
    }
   
    constructor(
      @Inject(OKTA_AUTH) public oktaAuth: OktaAuth, 
      private studentBotService: StudentAvaamoBotService,  
      private dynamicScriptLoader: ScriptLoaderService) {}

  ngOnInit() {
    this.chatbotId = sessionStorage.getItem('chatbotIdStudent');
    this.acknowledgmentVerified = sessionStorage.getItem('acknowledgementVerified');
    const oktaToken = JSON.parse(
      localStorage.getItem("okta-token-storage")
    ).accessToken;
    sessionStorage.setItem("access_token", oktaToken.accessToken);
    this.userAvaamoData();
    this.userData.email = sessionStorage.getItem("staffEmail");
   
  }

  async userAvaamoData() {
    const userDetails = await this.oktaAuth.getUser();
    sessionStorage.setItem("uid", userDetails.preferred_username);
    sessionStorage.setItem("userName", userDetails.name);
    sessionStorage.setItem("userEmail", userDetails.email);
    
    if (this.chatbotId && this.acknowledgmentVerified !== 'true' || this.acknowledgmentVerified == null) {
      this.checkAcknowledgment();
  } else if (this.acknowledgmentVerified == 'true') {
    this.showAcknowledge = false; 
    this.showFullscreen = true;
    this.boxArea = "box1 landing"
   this.buttonArea = "accepted_button";
  
   this.toshowTick = true;
   this.chatIconShow = true;
   this.loadScripts();
    
  }
  }


  private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader
      .load("chatbot")
      .then((data) => {
        
        getStaffAccessTokenForStaffbot();
      })
      .catch((error) => {
        console.log("Charlotte is currently unavailable", error);
        
      });
    
  }

  public readAcknowledgment() {
    this.showAcknowledge = !this.showAcknowledge;
  }

  public closeButton() {
    this.showAcknowledge = !this.showAcknowledge;
  }

  public startConversation() {
    this.showAcknowledge = !this.showAcknowledge;
    this.boxArea = "box1 landing";
    this.buttonArea = "accepted_button";
    this.disabledButton = true;
    this.toshowTick = true;
    this.chatIconShow = true;
    const user_email = sessionStorage.getItem('userEmail');
    const mode = 'set';
    this.sendAcknowledgement(this.chatbotId, user_email, mode);
    this.loadScripts();
    
  }

  public clickAcknowledgment() {
    this.isDisabled = !this.isDisabled;
  }

  checkAcknowledgment() {
  const user_email = sessionStorage.getItem('userEmail');
  const mode = 'get';
  this.sendAcknowledgement(this.chatbotId, user_email, mode);
  }

  acknowledgeTerms(checked: boolean) {
    this.acknowledged = checked;
    localStorage.setItem("termsAcknowledged", checked ? "true" : "false");
  }

  async logout() {
    sessionStorage.clear();
    this.removeAllCookies();
    sessionStorage.setItem("lastRoute", "/studentbot");
    await this.oktaAuth.signOut();
  }

  removeAllCookies() {
    const cookies = document.cookie.split(";");
    cookies.forEach((cookie) => {
      const [name] = cookie.split("=");
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/studentbot`;
    });
  }

  async sendAcknowledgement(chatbotId: string, userEmail: string, mode): Promise<void> {
    const body = new URLSearchParams();
    body.set('user_email', userEmail);
    body.set('mode', mode);
    body.set('chatbot_id', chatbotId);
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const subscriptionKey = environment.studentAsStaffConfig.apimSubscriptionKeyForStd;
    const token = await getValidAccessToken();
    if (environment.apimConfigurationEnable) {
      headers['Ocp-Apim-Subscription-Key'] = subscriptionKey;
      headers['AuthenticationType'] = 'Application';
      headers['Authorization'] = 'Bearer ' + token;
    }
    const response = await fetch(environment.commonConfig.acknowledgementAPI, {
      method: 'POST',
      headers: headers,
      body: body,
    });
  
    if (response.ok) {
      const responseData = await response.json();
      let acknowledgedStatus = responseData['Acknowledgement Status'];
      if (acknowledgedStatus === 'Not Found') {
        this.showAcknowledge = false; 
        sessionStorage.setItem('acknowledgementVerified', 'false');
        this.chatIconShow = false;
      } else if (acknowledgedStatus == 'yes'){
        this.showAcknowledge = false; 
        sessionStorage.setItem('acknowledgementVerified', 'true');
        //this.acknowledged = true;
        this.buttonArea = "accepted_button";
        this.toshowTick = true;
        this.chatIconShow = true;
        this.loadScripts();
        
      } else if (acknowledgedStatus == 'Success'){
       this.showAcknowledge = false;
       sessionStorage.setItem('acknowledgementVerified', 'true');
       this.boxArea = "box1 landing"
       this.buttonArea = "accepted_button";
       this.disabledButton = true;
       this.toshowTick = true;
       this.chatIconShow = true;
       this.loadScripts();
      
      }
      this.showFullscreen = true;
    } else {
      console.error('API error:', response.statusText);
    }
  }


}


  