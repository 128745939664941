declare function setApiUrlsForEnrollment(a: any): any;
declare function getChatbotIdForEnrollmentBot(): any;
declare function setApiUrlsForStudentStaff(a: any): any;
declare function getChatbotIdForStudentAzureBot(): any;
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AssetsPath } from 'src/static-assets/assets-path';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styles: []
})
export class AppComponent implements OnInit{
    public assetsPath = AssetsPath;
    private isAuthenticated:boolean = false;
    user: string = '';
  public title = `Chat bots for ${environment.name} University`;
  public userData = {
    name: 'no user',
    email: 'no user'
  };
  public userEmail: any;
  chatbotId = "";
  acknowledgmentVerified: string = '';
  public enrollmentRouteName: string = environment.enrollmentRouteName;
  public studentAzureRouteName: string = environment.studentAzureRouteName;
  public studentbotRouteName: string = environment.studentbotRouteName;
  private routerSubscription: Subscription | null = null;

  constructor(
    public authStateService: OktaAuthStateService, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
     @Inject(OKTA_AUTH) 
      private oktaAuth: OktaAuth
    ) {}
      

 async ngOnInit() {
    this.chatbotId = sessionStorage.getItem('chatbotIdEnr');
    setApiUrlsForEnrollment(environment.enrollmentConfig);
    this.acknowledgmentVerified = sessionStorage.getItem('acknowledgementVerified');
    setApiUrlsForStudentStaff(environment.studentAsStaffConfig);
    
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentRoute = event.urlAfterRedirects; 

        if (currentRoute.includes(this.enrollmentRouteName)) {
          getChatbotIdForEnrollmentBot();
        } else if (currentRoute.includes(this.studentbotRouteName) || currentRoute.includes(this.studentAzureRouteName)) {
          getChatbotIdForStudentAzureBot();
        }
      }
    });
  
}


}