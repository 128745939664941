// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    name: 'Walden', 
    assetBaseUrl:'https://genaichatbotqa.blob.core.windows.net/chatbots/',
    oktaAuthService:'oidc.okta',
    clientId:'0oa2342v06zO3TrHA0h8',
    issuer:'https://atge.oktapreview.com',
    // redirectUri:'http://localhost:4200/login/callback',
    redirectUri:'https://genai-dev.waldenu.edu/login/callback',
    oktaSignoutRedirectUri: 'https://genai-dev.waldenu.edu',
    studentAzureChatBotServiceFlag:true,
    studentGCPChatBotServiceFlag:true,
    studentAvaamoChatBotServiceFlag:true,
    chatbotName:'Charlotte',
    EnrollmentChatbotName: 'Enrollment Co-pilot',
    SASTokenForUnivercityLogo: 'sp=r&st=2024-11-19T13:34:53Z&se=2026-08-31T21:34:53Z&sv=2022-11-02&sr=b&sig=%2FHbHInnC4w%2BCxT32wm4H9%2Buix5x0VEj%2FaKYk7Vd6ab0%3D',
    SASTokenForChatbotIcon: 'sp=r&st=2024-11-18T14:21:23Z&se=2026-08-31T22:21:23Z&sv=2022-11-02&sr=b&sig=sq1rO%2BVz9Xa4Ki1Yg%2FwzNVvRVRVzszqE5VEW11WVIac%3D',
    SASTokenForFavicon: 'sp=r&st=2024-10-17T11:46:06Z&se=2026-05-31T19:46:06Z&sv=2022-11-02&sr=b&sig=bFOxUZWgVlNULsXokpoJaqvM%2BVVADeLa2INNGYiYNl4%3D',
    SASTokenForSudentAzureBot: 'sp=r&st=2024-11-29T12:57:55Z&se=2026-09-30T20:57:55Z&sv=2022-11-02&sr=b&sig=yIPi6fpdMzcrFkLPlCiGj2QGOQEAA9oo7p52FkEAqMo%3D',
    studentChatbotName: 'Avaamo chat Bot',
    chatbotIconForMessage: 'sp=r&st=2024-11-18T14:09:23Z&se=2026-08-31T22:09:23Z&sv=2022-11-02&sr=b&sig=BgN9mQaAporgBnDMfRFoxE8ZfX4g3x1N9M7IgmOYLqM%3D',
    SASTokenForLogoutButton: 'sp=r&st=2024-12-04T08:51:26Z&se=2026-11-01T16:51:26Z&sv=2022-11-02&sr=b&sig=78rZPFnnhjWYYkHB5D5RC96YWZH3%2F2SuONsVGHA2SRw%3D',
    apimConfigurationEnable: true,
    enrollmentRouteName: 'enrollmentcopilot',
    studentAzureRouteName: 'student-support-azure',
    studentbotRouteName: 'studentbot',

    studentAsStaffConfig: {
      apimSubscriptionKeyForStd: '53ef6fe4dcb4411cb7af211f78700b03',
      botApiUrl: "https://bis-api-dot-app-studentportal-dev.ue.r.appspot.com/staffassistantdev/token",
      chatUrl: 'https://charlotte-microapp-dev.web.app/assets/js/chatbot.js',
      channelId: '9f5def8c-ab6f-4b46-9219-28902133aac5',
      cachedResponseConstant: 'Saved Student Response',
      chatBotIdStd: "",
      secretKey: "",
    },

    enrollmentConfig: {
      apimSubscriptionKeyForEnr: 'dfc9558d15444b3293693dcd5b341d56',
      cachedResponseConstant: 'Saved Enrollment Response',
      chatBotIdEnr: "",
      salesforceConfigUrl: "https://laureateone--dev.sandbox.lightning.force.com",     
    },

    commonConfig: {
      acknowledgementAPI: "https://api-dev.waldenu.edu/wu-mmp-charlotte-chatbot-genai/1.0/acknowledgement_api/",
      chatHistory: "https://api-dev.waldenu.edu/wu-mmp-charlotte-chatbot-genai/1.0/get_chat_history_api/",
      citationPDF: "https://genaicopilotmmpdevblob.blob.core.windows.net/",
      citationPayloadLink: "https://api-dev.waldenu.edu/wu-mmp-charlotte-chatbot-genai/1.0/citation_category_group_api/",
      convoAction: "https://api-dev.waldenu.edu/wu-mmp-charlotte-chatbot-genai/1.0/convo_action/",
      convoFeedback: "https://api-dev.waldenu.edu/wu-mmp-charlotte-chatbot-genai/1.0/convo_feedback/",
      getAuthToken: "https://genai-copilot-mmp-dev.azurewebsites.net/get_token/",
      getChatbotId: "https://api-dev.waldenu.edu/wu-mmp-charlotte-chatbot-genai/1.0/get_chatbot_id/",
      refreshChat: "https://api-dev.waldenu.edu/wu-mmp-charlotte-chatbot-genai/1.0/clear_history_api/",
      sasCookies: "https://api-dev.waldenu.edu/wu-mmp-charlotte-chatbot-genai/1.0/create_user_delegation_sas_container/",
      sasToken: "https://api-dev.waldenu.edu/wu-mmp-charlotte-chatbot-genai/1.0/create_user_delegation_sas_container/",
      searchApiRequest: "https://api-dev.waldenu.edu/wu-mmp-charlotte-chatbot-genai/1.0/search_api_request/"
    },  

      Question1:"What support services are available for Walden students?",
      Question2:"How is Walden's faculty different from other online universities?",
      Question3:"Can you tell me about Walden's accreditation?"
};

(window as any).env = environment;

